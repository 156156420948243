import { defineStore } from "pinia";

import api from './api';

import {generateBearerHeader} from './helper.js'

import { useUserStore } from "./user.js";


export const useStudentInternship = defineStore('studentinternship',{
    state: ()=>({
        internshipData: [],
        isLoading: false,
    }),
    getters: {
        getInternshipData:(state)=>{
            return state.internshipData;
        },
    },
    actions:{
        resetInternshipData(){
            this.internshipData = [];
        },

        async fecthInternshipData(){
            try {
                this.isLoading = true;
                let userStore = useUserStore();
                let token = userStore.getToken;
                let headerData = generateBearerHeader(token);
                
                if (Object.keys(this.internshipData).length !== 0)
                {
                    return;
                }                
                const response = await api.get('/internship', {headers: headerData})

                
                if(response.status == 200){
                    this.resetInternshipData();
                    
                    
                    response.data.forEach(element => {
                        this.internshipData.push({
                            uuid: element.uuid,
                            internshipName: element.internship_name,
                            pharmacistName: element.pharmacist_name,
                            pharmacyName: element.pharmacy_name,
                            pharmacyAddress: element.pharmacist_address,
                            pharmacyPhoneNumber: element.pharmacist_phone,
                            pharmacyDistrict: element.pharmacist_district,
                            startDate: element.start_date,
                            endDate: element.end_date
                        });
                    });

                }
                
            }
            catch (error) {
                console.log(error);
                

            }
            finally{
                this.isLoading = false;
            }

            

        },
        reset(){
            this.internshipData = {};
            this.isLoading= false;
        }
    },
    persist: {
        storage: sessionStorage,
        paths: ['internshipData', 'isLoading']
    }

})


