<template>
    <v-btn 
        v-if="isLoggedIn"
        @click="userStore.logout()"
    >
        <v-icon>mdi-logout</v-icon>
        Çıkış Yap
    </v-btn>
</template>

<script setup>
import { computed } from 'vue';
import { useUserStore } from '@/store/user';

const userStore = useUserStore();
const isLoggedIn = computed(() => userStore.getIsLoggedIn);

</script>