import { defineStore } from 'pinia'
import api from './api';
import { generateBearerHeader } from './helper.js';
import { useUserStore } from './user.js';

export const useStudentLog = defineStore('studentlog', {
    state: ()=>({
        logData: [],
        isLoading: false,
        checkToday: false,
        message: ''
    }),
    getters: {
        getLogData: (state)=>{
            return state.logData;
        },
        getIsLoading: (state)=>{
            return state.isLoading;
        },
        getCheckToday: (state)=>{
            return state.checkToday;
        }
    },
    actions: {
        async resetLogData(){
            this.logData = [];
        },
        async fetchLogData(){
            try {
                this.isLoading = true;
                let userStore = useUserStore();
                let token = userStore.getToken;
                let headerData = generateBearerHeader(token);
                console.log('fetching log data ')

                const response = await api.get('/studentlog', {headers: headerData});


                if(response.status == 200){
                    await this.resetLogData();
                    response.data.forEach(element => {
                        this.logData.push({
                            ip: element.ip,
                            uuid: element.uuid,
                            created_at: element.created_at,
                            process: element.process,
                            log_date: element.log_date,
                        });
                    });
                }
            }
            catch (error) {
                console.log(error);
            }
            finally {
                this.isLoading = false;
                this.checkTodayLogData();
            }
        },
        async checkTodayLogData(){
            try {
                this.isLoading = true;
                let userStore = useUserStore();
                let token = userStore.getToken;
                let headerData = generateBearerHeader(token);
                console.log('fetching log data ')

                const response = await api.get('/studentlog/checktoday', {headers: headerData});

                if(response.status == 200){
                    if(response.data.message == 'Log exist'){
                        this.checkToday = true;
                    }
                    else {
                        this.checkToday = false;
                    }
                        

                }
            }
            catch (error) {
                console.log(error);
            }
            finally {
                this.isLoading = false;
            }
        },
        async fetchStudentLogData(uuid){
            try {
                this.isLoading = true;
                let userStore = useUserStore();
                let token = userStore.getToken;
                let headerData = generateBearerHeader(token);
                await this.resetLogData();
                const response = await api.get(`/comitee_members/logs/${uuid}`, {headers: headerData});
                console.log('fetching log data ')

                if(response.status == 200){
                    
                    response.data.forEach(element => {
                        this.logData.push({
                            ip: element.ip,
                            uuid: element.uuid,
                            created_at: element.created_at,
                            process: element.process,
                            log_date: element.log_date,
                        });
                    });
                }
            }
            catch (error) {
                console.log(error);
            }
            finally {
                this.isLoading = false;
            }
        },
        async insertLogData(){
            try {
                this.isLoading = true;
                let userStore = useUserStore();
                let token = userStore.getToken;
                let headerData = generateBearerHeader(token);

                const response = await api.post('/studentlog', {}, {headers: headerData});

                if(response.status == 201){
                    this.fetchLogData();
                    this.message = ' Yoklama kayıdı işlendi.'
                }
                else if(response.status == 200){
                    this.message = ' Zaten yoklama kaydı yapılmış.'
                }
            }
            catch (error) {
                console.log(error);
            }
            finally {
                this.isLoading = false;
            }
            
        }
    },
    persist: {
        storage: sessionStorage,
        paths: ['logData', 'isLoading','checkToday']
    }

});

