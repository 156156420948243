<template>
    
            <v-img class="ma-2"
            height="150"
            object-fit="cover"
            :src="createPhotoPath(studentPhoto)" 
            alt="Student Photo" />
    
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useUserStore } from '@/store/user';



const userStore = useUserStore();
const studentPhoto = computed(() => userStore.getStudentProfilePhoto);

const createPhotoPath = (photo) => {
    return photo ? `https://cloud-cube-us2.s3.amazonaws.com/${photo}` : 'https://via.placeholder.com/150';
    //return photo ? `http://localhost:8082/uploads/${photo}` : 'https://via.placeholder.com/150';
}

onMounted(() => {
    userStore.fetchStudentProfilePhoto();
    
});
</script>
