import { defineStore } from 'pinia';
import api from './api';
import { generateBearerHeader } from './helper';
import { useUserStore } from './user';





export const useComiteeMembersStore = defineStore('comiteemembers',{
    state: () => ({
        comiteeMembers: [],
        internshipRecords: [],
        internshipData: {},
        selectedInternshipUUID: null,
    }),
    getters: {
        getComiteeMembers: (state)=>{
            return state.comiteeMembers;
        },
        getIntershipRecords: (state)=>{
            return state.internshipRecords;
        },
        getInternshipData: (state)=>{
            return state.internshipData;
        },
        getSelectedInternshipUUID: (state)=>{
            return state.selectedInternshipUUID;
        }
    },
    actions: {
        async resetComiteeMembers(){
            this.comiteeMembers = [];
        },
        async resetInternshipRecords(){
            this.internshipRecords = [];
        },
        async setSelectedInternshipUUID(uuid){
            this.selectedInternshipUUID = uuid;
        },
        

        async fetchComiteeMembers(){
            let userStore = useUserStore();
            const token = userStore.getToken;
            const header = generateBearerHeader(token);
            try{
                const response = await api.get('comitee_members/members',{headers: header});
                if(response.status === 200){
                    this.resetComiteeMembers()
                    response.data.forEach(element => {
                        this.comiteeMembers.push(element);
                        
                    });
                }
            } catch (error){
                console.error(error);
            }
        },
        async fetchIntershipsRecords(){
            const token = useUserStore().getToken;
            const header = generateBearerHeader(token);
            try{
                const response = await api.get('comitee_members/students',{headers: header});
                if(response.status === 200){
                    this.resetInternshipRecords();
                    
                    response.data.forEach(element => {
                        this.internshipRecords.push(element);
                        
                    });
                }
            } catch (error){
                console.error(error.message);
            }
        },
        async fetchInternshipData(uuid){
            const token = useUserStore().getToken;
            const header = generateBearerHeader(token);
            try{
                const response = await api.get(`comitee_members/internship/${uuid}`,{headers: header});
                if(response.status === 200){
                    console.log(response.data);
                    this.internshipData = response.data;
                }
            } catch (error){
                console.error(error.message);

            }
        }

    },
    persist:
    {
        storage: sessionStorage,
        key: ['comiteeMembers','internshipRecords','internshipData','selectedInternshipUUID'],
    },

});