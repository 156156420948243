<template>
    <v-container>
        <h2 class="mt-4 mb-2">Kullanıcı Girişi</h2>
        <v-form>
            <v-row>
                <v-col>
                    <v-text-field label = "Kullanıcı Adı" variant="outlined" v-model="userName"></v-text-field>
                </v-col>
                <v-col>
                    <v-combobox variant ="outlined" label="Kullanıcı Türü" :items = "['Öğrenci', 'Staj Komite Üyesi']" default-value = "Öğrenci" v-model = "selectedRole"></v-combobox>
                </v-col>
            </v-row> 
            <v-row>
                <v-col>
                    <v-text-field label = "Şifre" type = "password" variant="outlined" v-model="password"></v-text-field>
                </v-col>
                <v-col></v-col>
            </v-row>
            <v-btn color="primary" @click="submitLogin" class="v-btn-no-caps">Giriş Yap</v-btn>        
        </v-form>
    </v-container>
</template>


<script setup>

import { useUserStore } from '@/store/user';
import {ref} from 'vue';

const selectedRole = ref('Öğrenci');
const userName = ref('');
const password = ref('');

const userStore = useUserStore();

const submitLogin = ()=>{
    if(userName.value === '' || password.value === ''){
        alert('Kullanıcı adı ve şifre boş bırakılamaz');
        return;
    }

    let parameters = {
        userName: userName.value,
        password: password.value,
        role: selectedRole.value
    }

    userStore.login(parameters);
}

</script>