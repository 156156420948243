<template>
    <v-card>
        <v-card-item class="bg-primary">
            <v-card-title>Devam Çizelgesi</v-card-title>
        </v-card-item>
        <v-card-item v-for="data in props.studentLogData" :key="data.uuid">
            <v-row>
              <v-col>{{ data.log_date }}</v-col>
              <v-col><v-chip prepend-icon="mdi-check" class="bg-success">Staj devam bilgisi işlendi</v-chip></v-col>
           </v-row>
          </v-card-item>
          <v-card-item></v-card-item>

    </v-card>
</template>


<script setup>

import { defineProps } from 'vue';


const props = defineProps({
    studentLogData: Array
});

</script>