<template>
    <v-card>
        <v-card-item class="bg-primary">
            <v-card-title>Devam Çizelgesi</v-card-title>
        </v-card-item>
        <v-card-item>
            <v-alert variant="tonal" type="info">Lütfen stajınız süresince staj başlangıcında devam kayıdı eklemeyi unutmayınız. Son beş yoklama kayıdını görüntülemektesiniz.</v-alert>
            <v-alert
                v-if = "message"
                border="start"
                close-label="Kayıt İşleme Bilgisi"
                color="deep-purple-accent-4"
                title="Closable Alert"
                variant="tonal"
                closable

            >
                {{ message }}
            </v-alert>

            <center><v-btn lg class="mt-2 mx-auto bg-primary" v-show="!checkToday"
                :loading="isLoading"
                @click ="saveLogData()" >
                Devam Bilgisi Ekle
            </v-btn>
        </center>
          </v-card-item>
          <v-card-item v-for="data in studentLogData" :key="data.uuid">
            <v-row>
              <v-col>{{ data.log_date }}</v-col>
              <v-col><v-chip prepend-icon="mdi-check" class="bg-success">Staj devam bilgisi işlendi</v-chip></v-col>
           </v-row>
          </v-card-item>

    </v-card>
</template>


<script setup>
import { onMounted, computed } from 'vue';
import { useStudentLog } from '@/store/studentlog';

const studentLogStore = useStudentLog();
const isLoading = computed(() => studentLogStore.getIsLoading);
const message = computed(() => studentLogStore.getMessage);
const checkToday = computed(() => studentLogStore.getCheckToday);

const studentLogData = computed(() => studentLogStore.getLogData);

const saveLogData = async () => {
    await studentLogStore.insertLogData();
    
};

onMounted(() => {
    studentLogStore.fetchLogData();
});


</script>