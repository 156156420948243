<template>
    <v-card>
        <v-card-item class="bg-pink-lighten-3">
            
            <v-card-title>
                <v-icon>mdi-account-group</v-icon>
                 Staj İzleme Komiteniz</v-card-title>
        </v-card-item>

        <v-card-item>
            <v-list lines="one">
                <v-list-item v-for="(item, index) in members" :key="index">
                    <v-list-item-content>
                        {{ item.name }}
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-item>
        
    </v-card>
</template>

<script setup>

import { onMounted, computed } from 'vue';

import { useStudentComiteeStore } from '@/store/studentcomitee';

const comiteeStore = useStudentComiteeStore();
const members = computed(()=> comiteeStore.getComiteeMembers);

onMounted(() => {
    comiteeStore.fetchComiteeMembers();
});



</script>