<template>
    <v-card>
        <v-card-item class="">
            <v-card-title class="">
                {{ memberName }}
            </v-card-title>
            <v-card-subtitle>{{ comiteeName }}</v-card-subtitle>
        </v-card-item>
        <v-divider></v-divider>
        
    </v-card>
</template>


<script setup>

import { onMounted, computed } from 'vue';
import { useUserStore } from '@/store/user';

const userStore = useUserStore();

const memberName = computed(() => userStore.getComiteeMemberName);
const comiteeName = computed(() => userStore.getComiteeName);


onMounted(() => {
    userStore.fetchComiteeMemberInformation();
})

</script>