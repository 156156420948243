<template>
   <v-sheet class="mx-auto" :max-width="500">
        <form @submit.prevent="uploadFile">
            <v-file-input
                accept="image/*"
                label="Resminizi Yükleyin"
                ref = "fileInput"
                @change = "handleFileChange"
            >
            </v-file-input>
            <v-btn type="submit" :disabled="!selectedFile">
                Yükle
            <v-overlay 
                v-model = "isLoading"
                contained
                class="align-center justify-center"

        >
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
        </v-overlay>
            </v-btn>
        </form>
    </v-sheet>    
</template>


<script setup>
    import { ref, computed } from 'vue';
    import { useUserStore } from '@/store/user';
    import axios from 'axios';

    const selectedFile = ref(null);
    const fileInput = ref(null);
    const userStore = useUserStore();
    const isLoading = computed(()=>userStore.getIsLoading);

    const uploadFile= async ()=>{
        

        if (!selectedFile.value) return;
        try{
            userStore.setIsLoading(true);
            const formData = new FormData();
            formData.append('file', selectedFile.value);
            await axios.post('https://backend.eczacilikstajsistemi.net/student_photo/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'accept': 'application/json',
                'Authorization': `Bearer ${userStore.getToken}`}});
        }
        catch(err){
            console.log(err);
        }
        finally{
            userStore.setIsLoading(false);
        }

        userStore.uploadStudentProfilePhoto(selectedFile.value);
    }

    const handleFileChange = ()=>{
            selectedFile.value = fileInput.value.files[0];
    }


</script>