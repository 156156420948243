<template>
    <v-sheet class="ma-2">
            <v-btn class="bg-blue-grey-lighten-2 v-btn-no-caps" @click="goBack"><v-icon>mdi-arrow-left-circle</v-icon> Geri Dön</v-btn>
    </v-sheet>
</template>



<script setup>

import { useRouter } from 'vue-router';


const router = useRouter();

const goBack = () => {
    router.go(-1);
}



</script>