import { defineStore} from 'pinia';
import api from './api';
import router from '@/router';
import {generateBearerHeader} from './helper.js'

import { useStudentInternship  } from './studentinternship.js'


export const useUserStore = defineStore('user',{
    state: ()=>({
        isLoggedIn: false,
        token: null,
        userRole: 1,
        userName: null,
        userSurName: null,
        userEmail: null,
        studentNumber: null,
        studentProfilePhoto: null,
        isLoading: false,
        changePasswordErrorMessage: null,
        passwordChanged: null,
        comiteeMemberName: null,
        comiteeName: null,

    }),
    getters: {
        getIsLoggedIn:(state)=>{
            return state.isLoggedIn;
        },
        getUserName: (state)=>{
            return state.userName;
        },
        getUserSurName: (state)=>{
            return state.userSurName;
        },
        getUserEMail: (state)=>{
            return state.userEmail;
        },
        getStudentNumber: (state)=>{
            return state.studentNumber;
        },
        getToken: (state)=>{
            return state.token;
        },
        getStudentProfilePhoto: (state)=>{
            return state.studentProfilePhoto;
        },
        getIsLoading: (state)=>{
            return state.isLoading;
        },
        getChangePasswordErrorMessage: (state)=>{
            return state.changePasswordErrorMessage;
        },
        getuserRole: (state)=>{
            return state.userRole;
        },
        getComiteeMemberName: (state)=>{
            return state.comiteeMemberName;
        },
        getComiteeName: (state)=>{
            return state.comiteeName;
        },

    },
    actions: {
        async getApiAdddress(payLoad){
            if(payLoad == 'Staj Komite Üyesi'){
                this.userRole = 2;
                return '/auth/member';
            }
            return '/auth/student';

        },
        
        async fetchComiteeMemberInformation(){
            let myHeader = {headers: generateBearerHeader(this.token)}
            try {
                const response = await api.get('/comitee_members/information', myHeader)
                if(response.status === 200){
                    this.comiteeMemberName = response.data.name;
                    this.comiteeName = response.data.comitee_name;
                }
            }
            catch(error){
                console.log(error);
                this.comiteeMemberName = null;
                this.comiteeName = null;
            }

        },
        async login(payLoad){
            let formData = new FormData();
            formData.append('username', payLoad.userName);
            formData.append('password', payLoad.password);
            let headers = { 'Content-Type': 'multipart/form-data' };
            let apiAddress = await this.getApiAdddress(payLoad.role);
            try {
                const response = await api.post(apiAddress, formData, headers);
                if (response.status === 200){
                    this.token = response.data.access_token;
                    this.isLoggedIn = true;
                    if(this.userRole == 1){
                        await this.fetchStudentInformation();
                        router.push({path: '/'});
                    }
                    else {
                        await this.fetchComiteeMemberInformation();
                        router.push({path: '/comitee'});
                    }
                }


            }
            catch (error){
                console.log(error);
            }

        },
        async fetchStudentInformation()
        {
            this.isLoading = true;
            api.get('/student/information', {headers: generateBearerHeader(this.token)}).then(response=>{
                if(response.status==200){
                    
                    this.studentNumber = response.data.student_number;
                    this.userName = response.data.student_name;
                    this.userSurName = response.data.student_surname;
                    this.userEmail = response.data.email;
                    this.isLoading = false;
                }
            }).catch(error=>{
                console.log(error);
                this.studentNumber = null;
                this.userName = null;
                this.userSurName = null;
                this.studentEMail = null;
                this.isLoading = false;
            })
        },
        reset(){
            this.isLoggedIn =false;
            this.token = null;
            this.userRole=  1;
            this.userName= null;
            this.userSurName= null;
            this.userEmail= null;
            this.studentNumber= null;
            this.studentProfilePhoto=  null;
            this.isLoading= false;
            this.changePasswordErrorMessage=null;
            this.passwordChanged = null;
            this.comiteeMemberName= null;
            this.comiteeName = null;
        },
        async logout(){
            
            const studentInternship = useStudentInternship();
            
            try 
            {
                
                const response = await api.get('/auth/logout', {headers: generateBearerHeader(this.token)})
                console.log()
                if(response.status === 200){
                    console.log('sistem çıkış başarılı.')
                }

            }
            catch{
                console.log('Çıkış hata meydana geldi')
            }
            this.reset();
            studentInternship.reset();
            router.push({path: '/login'});



        },
        async fetchStudentProfilePhoto(){
            let myHeader = {headers: generateBearerHeader(this.token)}
            try {
                const response = await api.get('/student_photo', myHeader)
                
                if(response.status === 204) // resim dosyası yüklememiş
                {
                    this.studentProfilePhoto = null;
                    return;
                }

                if(response.status === 200){
                    this.studentProfilePhoto = response.data.photo;
                }
                
            }
            catch(error){
                console.log(error);
                this.studentProfilePhoto = null;
            }
            
        },
        async uploadStudentProfilePhoto(photo){
            
            
            try {
                
                this.isLoading = true;
                let formData = new FormData();
                formData.append('file', photo);
                let sendHeader = {
                    headers: {
                        "Authorization": `Bearer ${this.token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                };
    
                const response = await api.post('/student_photo', formData, sendHeader)
                console.log(response.data)
                if(response.status === 201){
                    
                    this.fetchStudentProfilePhoto();
                }
                
                router.push({path: '/'});

                
            }
            catch(error){
                console.log(error)
                this.isLoading = false;
                router.push({path: '/'});
            }
            finally
            {
                this.isLoading = false;
            }
            
        },


        async changeStudentPassword(payLoad){


            let myHeader = { headers: generateBearerHeader(this.token) };
            
            try {
                const response = await api.post('/student/change_password', { old_password: payLoad.old_password, new_password: payLoad.new_password}, myHeader);
                if(response.status === 200){
                    this.passwordChanged = true;
                    return true;
                    
                }
                else{
                    this.passwordChanged = false;
                    this.changePasswordErrorMessage = 'Şifre değiştirme işlemi başarısız oldu. Lütfen tekrar deneyiniz.';
                    return false;
                
                }
            }
            catch(error){
                
                this.changePasswordErrorMessage = 'Şifre değiştirme işlemi başarısız oldu. Lütfen tekrar deneyiniz.';
                return false;

            }
        
        },
        setIsLoading(value){
            this.isLoading = value;
        }
    },
    persist: {
        storage: sessionStorage,
        paths: ['isLoggedIn', 'token', 'userRole', 'userName', 'userSurName', 'userEmail', 
                    'studentProfilePhoto', 'studentNumber', 'comiteeMemberName', 'comiteeName']

    }



});