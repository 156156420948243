<template>
    <v-card>
        <v-card-item class="bg-teal-lighten-3">
            <v-card-title >
                <v-icon>mdi-calendar</v-icon>
                Takvim
            </v-card-title>
        </v-card-item>
        <v-divider></v-divider>
        <v-card-item >
            <v-alert v-if="calendarStatus==0" type="warning" variant="tonal">Henüz Takviminizi Doldurmadınız. </v-alert>
            <v-alert v-if="calendarStatus==1" type="warning" variant="tonal">Henüz Takviminizi Doldurmadınız. </v-alert>
            <v-alert v-if="calendarStatus==2" type="info" variant="tonal">Takviminiz henüz onaylanmadı. Düzenleme yapabilirsiniz. </v-alert>
            <v-alert v-if="calendarStatus==3" type="success" variant="tonal">Takviminiz Staj Komisyonu tarafından onaylandı. </v-alert>
        </v-card-item>

        <v-card-item>
            <v-btn @click="gotoCalendar()" color="bg-green-lighten-2" variant="tonal" class="v-btn-no-caps">Takvime Git</v-btn>
        </v-card-item>
    </v-card>
</template>

<script setup>

import {onMounted, computed} from 'vue';

import router from '@/router';
import { useInternshipCalendar } from '@/store/internshipcalendar';

const calendarStore = useInternshipCalendar();
const calendarStatus = computed(()=>calendarStore.getCalendarStatus);


const gotoCalendar = () => {
    router.push('/calendar');
}

onMounted(() => {
    calendarStore.fetchCalendarStatus();
});
</script>
