import axios from 'axios';

const config = {
    baseURL: 'https://backend.eczacilikstajsistemi.net',
    //baseURL: 'http://localhost:8082',
    header: {}
}

const api = axios.create(config);

export default api;