<template>
<v-container>
    <v-row>
        
        <go-back-nav></go-back-nav>
    </v-row>
    <v-row>
        <v-col>
            <student-calendar :uuid = "route.params.id"></student-calendar>
            
        </v-col>
    </v-row>
</v-container>
</template>

<script setup>

import GoBackNav from '@/components/GoBackNav.vue';
import StudentCalendar from '@/components/StudentCalendar.vue';
import { useRoute } from 'vue-router';
const route = useRoute();
</script>
