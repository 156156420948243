<template>
                <v-card>
                            <v-card-item class="bg-red-lighten-2">
                                    <v-card-title>
                                    Komitenize Atanan Stajyer Eczacılık Öğrencileri
                                    
                                    </v-card-title>
                                    
                            </v-card-item>

                            <v-card-item class="bg-grey-lighten-5" v-for="item in intershipsRecords" :key="item.uuid">
                                <v-row>
                                    <v-col>
                                        <v-avatar size="80" rounded="0">
                                            <v-img v-if="item.photo === null" :src=defaultPhoto></v-img>
                                            <v-img v-if="item.photo !== null" :src=createPhotoPath(item.photo)></v-img>


                                        </v-avatar>
                                        
                                    </v-col>
                                    <v-col>{{ item.student_number }}</v-col>
                                    <v-col>{{ item.student_name}} {{  item.student_surname }}</v-col>
                                    <v-col>{{ item.pharmacy_name}}</v-col>
                                    <v-col>{{ item.district }}</v-col>
                                    <v-col cols="auto">
                                        <v-row>
                                            <v-col>
                                                <v-btn class="bg-pink-darken-2 v-btn-no-caps">
                                                    Staj Bilgileri
                                                    <v-menu activator="parent">
                                                        <v-list>
                                                            <v-list-item @click = "showInfoDialog(item.uuid)">Eczane Bilgileri</v-list-item>
                                                            <v-list-item @click = "goToCalendarPage(item.uuid)">Staj Takvimi</v-list-item>
                                                            <v-list-item @click = "showLogDialog(item.uuid)">Yoklama Bilgileri</v-list-item>
                                                        </v-list>

                                                    </v-menu>
                                                </v-btn>

                                            </v-col>
                                            <v-col>
                                                <v-btn class="bg-pink-darken-2 v-btn-no-caps">
                                                    Staj Formları
                                                    <v-menu activator="parent">
                                                        <v-list>
                                                            <v-list-item @click = "goToFirstFormPage(item.uuid)">Staj İzleme Formu - I</v-list-item>
                                                            <v-list-item @click = "goToSecondFormPage(item.uuid)">Staj İzleme Formu - II</v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                </v-btn>
                                            </v-col>

                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                            </v-card-item>

                            <v-dialog v-model = "showDialogInfo" max-width="600">
                                <student-internship-info :value =showDialog :uuid = showUUID :dataInfo=intershipData[0] @close = "showDialogInfo=false"></student-internship-info>
                            </v-dialog>

                            <v-dialog v-model = "showLog" max-width="600">
                                <comitee-member-student-log-card :value =showDialog :studentLogData=logData  @close = "showLog=false"></comitee-member-student-log-card>
                            </v-dialog>


                </v-card>




</template>

<script setup>

import { onMounted, computed, ref } from 'vue';
import { useStudentLog } from '@/store/studentlog';
import { useComiteeMembersStore } from '@/store/comiteemembers';
import  StudentInternshipInfo  from '@/components/StudentInternshipInfo.vue';
import ComiteeMemberStudentLogCard from '@/components/ComiteeMemberStudentLogCard.vue';
import { useRouter } from 'vue-router';


const comiteeMemberStore = useComiteeMembersStore();
const studentLogStore = useStudentLog();

const intershipsRecords = computed(()=>comiteeMemberStore.getIntershipRecords);
const intershipData = computed(()=>comiteeMemberStore.getInternshipData);

const logData = computed(()=>studentLogStore.getLogData);

const router = useRouter();

let showDialogInfo = ref(false);
let showLog = ref(false);
let showUUID = ref(null);



const createPhotoPath = (photo) => {
    
    return `https://cloud-cube-us2.s3.amazonaws.com/${photo}`;
    
}


const defaultPhoto = require('@/assets/no-profile-photo.jpg');

const showInfoDialog = async (uuid) => {
        await comiteeMemberStore.fetchInternshipData(uuid);
        showUUID.value = uuid;
        showDialogInfo.value = true;
}

const showLogDialog = async (uuid) =>{
    console.log('Show dialog'+uuid);
    await studentLogStore.fetchStudentLogData(uuid);
    showLog.value = true;

}


const goToCalendarPage = (uuid) => {
    console.log('Go to calendar page'+ uuid);
    router.push('/comitee/calendar/'+uuid);
}

const goToFirstFormPage = (uuid) => {
    console.log('Go to first form page'+ uuid);
    router.push('/comitee/firstform/'+uuid);
}

const goToSecondFormPage = (uuid) => {
    console.log('Go to second form page'+ uuid);
    router.push('/comitee/secondform/'+uuid);
}



onMounted(() => {
    comiteeMemberStore.fetchIntershipsRecords();
})

</script>