<template>
    
        <v-card>
        <v-card-title>
            <v-icon>mdi-home</v-icon>
             {{ dataInfo.internship_name }} 
        </v-card-title>
        <v-card-item>
                <v-row no-gutter>
                    <v-col cols="4" class="text-blue">Tarih</v-col>
                    <v-col cols="6">{{ formatTarih(dataInfo.start_date) }} - {{ formatTarih(dataInfo.end_date) }} </v-col>
                </v-row>
                <v-row no-gutter>
                    <v-col cols="4" class="text-blue">Staj Yapılan Eczane</v-col>
                    <v-col cols="6">{{ dataInfo.pharmacy_name }}</v-col>
                </v-row>
                <v-row no-gutter>
                    <v-col cols="4" class="text-blue">Eczacı</v-col>
                    <v-col cols="6">{{ dataInfo.pharmacist_name }}</v-col>
                </v-row>
                <v-row no-gutter>
                    <v-col cols="4" class="text-blue">Adres</v-col>
                    <v-col cols="6">
                        {{ dataInfo.pharmacist_address }}<br>
                        {{ dataInfo.pharmacist_district }}<br>
                        

                    </v-col>
                </v-row>
                <v-row no-gutter>
                    <v-col cols="4" class="text-blue">Telefon</v-col>
                    <v-col cols="6">{{ dataInfo.pharmacist_phone }}<br></v-col>
                </v-row>
            </v-card-item>
            <v-card-item>
                <v-row>
                    <v-col></v-col>
                    <v-col class="pa-4 text-bottom" cols="2">
                        <v-btn color = "secondary" class="v-btn-no-caps" @click = "closeDialog">Kapat</v-btn>
                    </v-col>
                </v-row>
                
            </v-card-item>
        </v-card>
    
    


</template>


<script setup>

import { defineProps, computed, defineEmits } from 'vue';
import { formatTarih } from '@/store/helper';

const props = defineProps({
    uuid: String,
    dataInfo: Array, 
    value: Boolean
});

const emit = defineEmits(['close']);

const closeDialog = ()=>{
    emit('close');
}

 const dataInfo = computed(()=>props.dataInfo);




</script>