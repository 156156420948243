function generateBearerHeader(token){
    let header = {"Authorization": `Bearer ${token}`}
    return header
}

function formatTarih(tarih){
    const date = new Date(tarih);
    const day = date.getDate().toString().padStart(2, '0'); // Gün
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ay (0 ile başlar, 0 Ocak'ı temsil eder)
    const year = date.getFullYear(); // Yıl
  
    return `${day}.${month}.${year}`;
}

module.exports = {
    generateBearerHeader,
    formatTarih
}