<template>
    <v-card>
        <v-card-item class="bg-red-lighten-2">
            <v-card-title class="">İzleme Komitesi Üyeleri</v-card-title>
            </v-card-item>

        <v-card-item v-for="item in comiteeMembers" :key="item.uuid">
            {{ item.name }}
        </v-card-item>

    </v-card>

</template>


<script setup>
    import {onMounted, computed} from 'vue';
    import { useComiteeMembersStore } from '@/store/comiteemembers';
   
    const comiteeMembersStore = useComiteeMembersStore();
    const comiteeMembers = computed(()=>comiteeMembersStore.getComiteeMembers);
    

    onMounted(() => {
        comiteeMembersStore.fetchComiteeMembers();
    });


</script>