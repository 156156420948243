import { defineStore } from "pinia";
import api from './api';
import { generateBearerHeader } from './helper';
import { useUserStore } from './user';

export const useFirstFormStore = defineStore('firstformstore',{
    state: () => ({
        internship: null,
        formData: {},
        isChanged: false,
        isLoading: false

    }),
    getters: {
        getFormData: (state)=>{
            return state.formData;
        },
        getIsChanged: (state)=>{
            return state.isChanged;
        },
        getIsLoading: (state)=>{
            return state.isLoading;
        }
    },
    actions: {
        resetFormData(){
            this.formData = {};
        },
        updateHasChanged(payLoad){
            this.isChanged = payLoad;
        },
        async updateFormData(internshipID, formTitle){
            const userStore = useUserStore();
            const token = userStore.getToken;
            const header = generateBearerHeader(token);
            this.isLoading = true;
            try{
                const response = await api.post(`/comitee_members/form/update/?uuid=${internshipID}&form_title=${formTitle}`, this.formData, {headers: header});
                if(response.status == 200){
                    this.isChanged = false;
                    this.fetchFormData(internshipID, formTitle);
                    
                }
                else{
                    return false;
                }

            }
            catch(error){
                console.log(error);
            }
            finally{
                this.isLoading = false;
            }

        },

        async formStatus(internshipID, formTitle){
            const userStore = useUserStore();
            const token = userStore.getToken;
            const header = generateBearerHeader(token);
            this.isLoading = true;
            try{
                const response = await api.get(`/comitee_members/form/stat/${internshipID}/${formTitle}`, {headers: header});
                if(response.status == 200){
                    return response.data.status;
                }
            }
            catch(error){
                console.log(error);
            }
            finally{
                this.isLoading = false;
            }
        },
        async fetchFormData(internshipID, formTitle){
            const userStore = useUserStore();
            const token = userStore.getToken;
            const header = generateBearerHeader(token);
            this.isLoading = true;
            try{
                let response = await api.get(`/comitee_members/form/${internshipID}/${formTitle}`, {headers: header});
                if(response.status == 200){
                    if(response.data.form_data  == {}){
                        if(formTitle == 'Form-2'){
                            this.formData = {
                                tarih: '',
                                saat: '',
                                ogrenci: 1,
                                neden: '',
                                devam: 1,
                                devamneden: '',
                                sorun: 1,
                                sorunneden: ''

                            };
                        }
                        else {
                            this.formData = {
                                tarih: '',
                                saat: '',
                                ogrenci: 1,
                                neden: '',
                                uygunneden: '',
                                A1: null,
                                A2: null,
                                A3: null,
                                B1: null,
                                B2: null,
                                B3: null,
                                B4: null,
                                B5: null,
                                B6: null,
                            };
    
                        }


                        
                    }
                    else{
                        this.formData = response.data.form_data;
                    }

                }
            }
            catch(error){
                console.log(error);
            }
            finally{
                this.isLoading = false;
            }
        }
    },
    persist: {
        storage: sessionStorage,
        paths: ['formData', 'isLoading','isChanged']
    }


})