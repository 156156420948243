<template>
    <v-card>
        <v-card-item class="bg-red-accent-1">
            
            <v-card-title>
                <v-icon>mdi-home</v-icon>
                Staj Bilgileriniz
            </v-card-title>
            
        </v-card-item>

        
        <v-span v-for="data in internshipsData" :key="data.uuid">
            
            <v-card-item>
                <v-row no-gutter>
                    <v-col>{{ data.internshipName }} ({{ formatTarih(data.startDate) }} - {{ formatTarih(data.endDate)  }})</v-col>
                    
                </v-row>
            </v-card-item>

            <v-card-item>
                <v-row no-gutter>
                    <v-col cols="4" class="text-blue">Staj Yapacağınız Eczane</v-col>
                    <v-col cols="6">{{ data.pharmacyName }}</v-col>
                </v-row>
            </v-card-item>
            <v-divider></v-divider>
            <v-card-item>
                <v-row no-gutter>
                    <v-col cols="4" class="text-blue">Eczacınızın Adı</v-col>
                    <v-col cols="6">{{ data.pharmacistName }}</v-col>
                </v-row>
            </v-card-item>
            <v-divider></v-divider>
            <v-card-item>
                <v-row no-gutter>
                    <v-col cols="4" class="text-blue">Eczacınızın Telefon Numarası</v-col>
                    <v-col cols="6" class="ma-2 pa-2">{{ data.pharmacyPhoneNumber }}</v-col>
                </v-row>
            </v-card-item>
            <v-divider></v-divider>
            <v-card-item>
                <v-row no-gutter>
                    <v-col cols="4" class="text-blue">Adresi:</v-col>
                    <v-col cols="6">
                        <p>{{ data.pharmacyAddress }}</p>
                        <p>{{ data.pharmacyDistrict }}</p>
                    </v-col>
                </v-row>
        </v-card-item>
        </v-span>



        <v-overlay 
            v-model = "isLoading"
            contained
            class="align-center justify-center"

        >
                    <v-progress-circular
                        :size="64"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>

        </v-overlay>         
    </v-card>
</template>

<script setup>

import { useStudentInternship } from '@/store/studentinternship';
import { formatTarih } from '@/store/helper';
import { onMounted, computed } from 'vue';

const internshipStore = useStudentInternship();
const internshipsData = computed(() => internshipStore.getInternshipData);


onMounted(() => {
   internshipStore.fecthInternshipData();
   
});

</script>
