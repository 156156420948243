import { createRouter, createWebHistory } from 'vue-router'
import StudentHomeView from '../views/StudentHomeView.vue'
import LoginView from '../views/LoginView.vue'
import StudentCalendarView from '../views/StudentCalendarView'
import UploadPhotoView from '../views/UploadPhotoView'
import ChangePasswordView from '../views/ChangePasswordView'
import ComiteeHomeView from '../views/ComiteeHomeView'
import ComiteeCalendarView from '../views/ComiteeCalendarView'
import FormHome from '../views/FormHome'
import SecondFormHome from '../views/SecondFormHome'


import { useUserStore } from '../store/user.js'

const routes = [
  {
    path: '/',
    name: 'home',
    component: StudentHomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: StudentCalendarView
  },
  {
    path: '/uploadphoto',
    name: 'uploadphoto',
    component: UploadPhotoView
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: ChangePasswordView
  },
  {
    path: '/comitee',
    name: 'comiteehome',
    component: ComiteeHomeView
  },
  {
    path:'/comitee/calendar/:id',
    name: 'comiteecalendar',
    component: ComiteeCalendarView
  },
  {
    path: '/comitee/firstform/:id',
    name: 'comiteeform',
    component: FormHome

  },
  {
    path: '/comitee/secondform/:id',
    name: 'secondform',
    component: SecondFormHome

  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to) => {
  document.title = to.meta.title || 'Eczacılık Staj Sistemi - EÜEF Staj Komisyonu'; // Varsayılan başlık
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  if (to.name !== 'login' && !userStore.getToken) {
    next({ name: 'login' });
  } else {
    if (to.path === '/' && userStore.getuserRole === 2) {
      next({ name: 'comiteehome' });
    } else {
      next();
    }
  }
});

export default router
