import { defineStore } from "pinia"
import { useUserStore } from "./user"
import { generateBearerHeader } from "./helper"
import api from "./api.js";

export const useStudentComiteeStore = defineStore('studentcomitee', {
    state: ()=>({
        comiteeMembers : [],
        isLoading: false,
        comiteeErrorMessage: null        

    }),
    getters: {
        getComiteeMembers: (state) =>{ 
            return state.comiteeMembers;
        }
    },
    actions: {
        async resetComiteeMembers(){
            this.comiteeMembers = [];
        },
        async fetchComiteeMembers(){
            try {
                this.isLoading = true;
                let userStore = useUserStore();
                let token = userStore.getToken;
                let headerData = generateBearerHeader(token);
    
                const response = await api.get('/internship/comitee', {headers: headerData})
                
                if(response.status == 200){
                    await this.resetComiteeMembers();
                    this.comiteeMembers = response.data;
                }
            }
            catch(error){
                console.log(error);
            }
            finally{
                this.isLoading = false;
            }
        }
    },
    persist: {
        storage: sessionStorage,
        paths: ['isLoading', 'comiteeMembers']
    }

    

})