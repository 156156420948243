<template>
<v-card>
  <v-card-item class="bg-teal-lighten-2">
    <v-card-title>Şifre Değiştir</v-card-title>
  </v-card-item>
  <v-divider></v-divider>
  <v-card-item v-if="errorMessage">
    <v-alert type="error">{{ errorMessage }}</v-alert>
  </v-card-item>
  
  <v-card-item>
    <v-form @submit.prevent="changePassword()" ref="form">
      <v-text-field
        v-model="oldPassword"
        label="Eski Şifre"
        type="password"
        required
      ></v-text-field>
      <v-text-field
        v-model="newPassword"
        label="Yeni Şifre"
        type="password"
        required
        :rules="[requiredRule, passwordComplexityRule]"
      ></v-text-field>
      <v-text-field
        v-model="confirmPassword"
        label="Yeni Şifre (Tekrar)"
        type="password"
        required
        :rules="[requiredRule, passwordMatchRule]"
      ></v-text-field>
      <v-btn type="submit" :loading="isLoading" color="primary" class="v-btn-no-caps">Şifreyi Değiştir</v-btn>
    </v-form>
  </v-card-item>
</v-card>

</template>

<script setup>
import { useUserStore } from '@/store/user';
import { computed } from 'vue';
import { ref } from 'vue';
import router from '@/router';

const oldPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');
const isLoading = ref(false);

const userStore = useUserStore();

const errorMessage = computed(() => userStore.getChangePasswordErrorMessage);


const changePassword = async () => {
  isLoading.value = true;
  try {
    let passwordData = {
      old_password: oldPassword.value,
      new_password: newPassword.value,
    };
    const result = await userStore.changeStudentPassword(passwordData);
    if (result==true)
    {
      router.push('/');
    }
    else
    {
      console.log("Şifre değiştirme başarısız");
    }
    
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
};

const passwordComplexityRule = (v) => /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/.test(v) || 'Şifreniz en az 8 karakterden oluşmalıdır ve en az bir büyük/küçük harf ve bir rakam içermelidir';

const requiredRule = (v) => !!v || 'Bu alan zorunludur';

const passwordMatchRule = (v) => v === newPassword.value || 'Şifreler uyuşmuyor';


</script>
