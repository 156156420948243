<template>
  <v-container>
    <v-row class="mt-2" align-top>
      <v-col>
        <student-card></student-card>
      </v-col>
    </v-row>
     <v-row>
      <v-col>
        <internship-card></internship-card>
      </v-col>
      <v-col>
        <comitee-info></comitee-info>
      </v-col>
     </v-row>    
    <v-row class="mt-2">
      <v-col>
        <calendar-info></calendar-info>
      </v-col>
      <v-col>
        <student-log-card></student-log-card>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { useUserStore } from '@/store/user';
import { useRouter } from 'vue-router';

import StudentCard from '@/components/StudentCard.vue';
import InternshipCard from '@/components/InternshipCard.vue';
import CalendarInfo from '@/components/CalendarInfo.vue';
import ComiteeInfo from '@/components/ComiteeInfo.vue';
import StudentLogCard from '@/components/StudentLogCard.vue';

const userStore = useUserStore();
const isLoggedIn = computed(() => userStore.getIsLoggedIn);


  

const router = useRouter();

onMounted(() => {
  
  if (!isLoggedIn.value) {
    router.push('/login');
  }
});
</script>
