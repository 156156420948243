<template>
    <v-card>
        <v-card-item class="bg-primary">
            <v-card-title class="text-center">Staj İzleme Ziyareti - I</v-card-title>
        </v-card-item>
        <v-card-item>
            <v-row>
                <v-col cols="2">Tarih</v-col>
                <v-col cols="2">
                        <v-text-field variant="outlined" type="date" max-width="100" v-model="formData.tarih" v-on:change="formUpdated"></v-text-field>
                </v-col>
               <v-col></v-col>
            </v-row>
        </v-card-item>
        <v-card-item>
            <v-row>
                <v-col cols="2">Saat</v-col>
                <v-col cols="2">
                    <v-text-field variant="outlined" type="time" class="w-auto" v-model = "formData.saat" v-on:change="formUpdated"></v-text-field>
                </v-col>
                <v-col></v-col>
            </v-row>
        </v-card-item>
        <v-divider></v-divider>
        <v-card-item>
            <v-row>
                <v-col cols="2">Ziyaret sırasında öğrenci eczanededir.</v-col>
                <v-col cols="2">
                    <v-radio-group inline v-model="formData.ogrenci" v-on:change="formUpdated">
                        <v-radio label="Evet" value="1"></v-radio>
                        <v-radio label="Hayır" value="0"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">Hayırsa nedeni :</v-col>
                <v-col><v-text-field variant="outlined" v-model="formData.neden" v-on:change="formUpdated"></v-text-field></v-col>
            </v-row>
        </v-card-item>
    </v-card>
    <v-card class="mt-4">
        <v-card-item class="bg-teal-lighten-2">
            <v-card-title>A. Staj Yeri Organizasyonu ve Yönetimi</v-card-title>
        </v-card-item>
        <v-card-item>
            <v-row>
                <v-col cols="3">
                    A1. Stajyerin staj yerine oryantasyonu yeterlidir.
                </v-col>
                <v-col>
                    <v-radio-group inline v-model="formData.A1" v-on:change="formUpdated">
                        <v-radio label="Çok iyi" value="5"></v-radio>
                        <v-radio label="İyi" value="4"></v-radio>
                        <v-radio label="Orta" value="3"></v-radio>
                        <v-radio label="Zayıf" value="2"></v-radio>
                        <v-radio label="Fikrim yok" value="1"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-card-item>
        <v-card-item>
            <v-row>
                <v-col cols="3">
                    A2. Eğitmen eczacı stajyere yeterli danışmanlık vermektedir.
                </v-col>
                <v-col>
                    <v-radio-group inline v-model="formData.A2" v-on:change="formUpdated">
                        <v-radio label="Çok iyi" value="5"></v-radio>
                        <v-radio label="İyi" value="4"></v-radio>
                        <v-radio label="Orta" value="3"></v-radio>
                        <v-radio label="Zayıf" value="2"></v-radio>
                        <v-radio label="Fikrim yok" value="1"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-card-item>
        <v-card-item>
            <v-row>
                <v-col cols="3">
                    A3. Eczane bir sonraki dönem staj için uygundur.
                </v-col>
                <v-col>
                    <v-radio-group inline v-model="formData.A3" v-on:change="formUpdated">
                        <v-radio label="Evet" value="1"></v-radio>
                        <v-radio label="Hayır" value="0"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>    
            <v-row>
                <v-col cols="2">Hayırsa nedeni :</v-col>
                <v-col><v-text-field variant="outlined" v-model="formData.uygunneden" v-on:change="formUpdated"></v-text-field></v-col>
            </v-row>
        </v-card-item>                
    </v-card>
    <v-card class="mt-4">
        <v-card-item class="bg-teal-lighten-2">
            <v-card-title>B. Eğitmen Eczacıya Yöneltilecek Sorular</v-card-title>
        </v-card-item>
        <v-card-item>
            <v-row>
                <v-col cols="3">
                    B1. Öğrencinin mesleki uygulamalardaki becerisi yeterlidir.
                </v-col>
                <v-col>
                    <v-radio-group inline v-model="formData.B1" v-on:change="formUpdated">
                        <v-radio label="Çok iyi" value="5"></v-radio>
                        <v-radio label="İyi" value="4"></v-radio>
                        <v-radio label="Orta" value="3"></v-radio>
                        <v-radio label="Zayıf" value="2"></v-radio>
                        <v-radio label="Fikrim yok" value="1"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-card-item>
        <v-card-item>
            <v-row>
                <v-col cols="3">
                    B2. Öğrencinin iletişim becerisi yeterlidir.
                </v-col>
                <v-col>
                    <v-radio-group inline v-model="formData.B2" v-on:change="formUpdated">
                        <v-radio label="Çok iyi" value="5"></v-radio>
                        <v-radio label="İyi" value="4"></v-radio>
                        <v-radio label="Orta" value="3"></v-radio>
                        <v-radio label="Zayıf" value="2"></v-radio>
                        <v-radio label="Fikrim yok" value="1"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-card-item>
        <v-card-item>
            <v-row>
                <v-col cols="3">
                    B3. Öğrenci bilgi ve becerisini arttırma çabası içerisindedir.
                </v-col>
                <v-col>
                    <v-radio-group inline v-model="formData.B3" v-on:change="formUpdated">
                        <v-radio label="Çok iyi" value="5"></v-radio>
                        <v-radio label="İyi" value="4"></v-radio>
                        <v-radio label="Orta" value="3"></v-radio>
                        <v-radio label="Zayıf" value="2"></v-radio>
                        <v-radio label="Fikrim yok" value="1"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-card-item>
        <v-card-item>
            <v-row>
                <v-col cols="3">
                    B4. Öğrenci eczane işlerine katılma konusunda isteklidir.
                </v-col>
                <v-col>
                    <v-radio-group inline v-model="formData.B4" v-on:change="formUpdated">
                        <v-radio label="Çok iyi" value="5"></v-radio>
                        <v-radio label="İyi" value="4"></v-radio>
                        <v-radio label="Orta" value="3"></v-radio>
                        <v-radio label="Zayıf" value="2"></v-radio>
                        <v-radio label="Fikrim yok" value="1"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-card-item>
        <v-card-item>
            <v-row>
                <v-col cols="3">
                    B5. Öğrencinin staj saatlerine uyumu ve devamı yeterlidir.
                </v-col>
                <v-col>
                    <v-radio-group inline v-model="formData.B5" v-on:change="formUpdated">
                        <v-radio label="Çok iyi" value="5"></v-radio>
                        <v-radio label="İyi" value="4"></v-radio>
                        <v-radio label="Orta" value="3"></v-radio>
                        <v-radio label="Zayıf" value="2"></v-radio>
                        <v-radio label="Fikrim yok" value="1"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-card-item>
        <v-card-item>
            <v-row>
                <v-col cols="3">
                    B6. Gelecek sene tekrar stajyer öğrenci kabul etmeyi düşünüyor musunuz?
                </v-col>
                <v-col>
                    <v-radio-group inline v-model="formData.B6" v-on:change="formUpdated">
                        <v-radio label="Evet" value="1"></v-radio>
                        <v-radio label="Hayır" value="0"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-card-item>
    </v-card>
    <v-card class="mt-4">
        <v-card-item>
            <v-row>
                <v-col>
                    <v-btn class="bg-primary" :disabled="!isChanged" @click="submitForm()">Kaydet</v-btn>
                    
                    
                    
                </v-col>
                    
            </v-row>
            
            
        </v-card-item>
        <v-overlay 
            v-model = "isLoading"
            contained
            class="align-center justify-center"

        >
                    <v-progress-circular
                        :size="64"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>

        </v-overlay> 


    </v-card>


</template>


<script setup>


import { onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useFirstFormStore } from '@/store/firstform';


const route = useRoute();
const firstFormStore = useFirstFormStore();
const formData = computed(()=>firstFormStore.getFormData);
const isChanged = computed(()=>firstFormStore.getIsChanged);
const isLoading = computed(()=>firstFormStore.getIsLoading);

onMounted(() => {
    firstFormStore.fetchFormData(route.params.id, 'Form-1');
});

const formUpdated = ()=>{
    firstFormStore.updateHasChanged(true);
}

const submitForm = ()=>{
    firstFormStore.updateFormData(route.params.id, 'Form-1');
}

</script>