import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// vuetify
import '@mdi/font/css/materialdesignicons.css' 
import colors from 'vuetify/lib/util/colors'
import 'vuetify/styles';

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { createPinia } from 'pinia'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'

const pinia = createPinia({

})

pinia.use(piniaPluginPersistedState);

const myColors = {
  blueyNavy: '#3E3D65',
  blueyBlue: '#87C8F9'

}

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi'
  },
  theme: {
    themes: {
        light: {
            ...colors,
            blueyNavy: myColors.blueyNavy,
            blueyBlue: myColors.blueyBlue
        },
        dark: {
            ...colors,
            blueyNavy: myColors.blueyNavy,
            blueyBlue: myColors.blueyBlue
        }
    }
  }
})

createApp(App)
    .use(pinia)
    .use(vuetify)
    .use(router)
    .mount('#app')
