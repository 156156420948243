<template>
<v-card
    class="mx-auto"
    
>
    <v-card-item class="bg-blue-lighten-3 text-indigo">
        <v-card-title>
            <v-icon>mdi-account-school</v-icon>
            {{ userName }} {{ userSurName }}
        </v-card-title>
    </v-card-item>
    <v-card-item>
        <v-row no-gutters>
            <v-col cols="4" class="text-blue">Öğrenci Numaranız:</v-col>
            <v-col cols="6">{{ studentNumber }}</v-col>
        </v-row> 
    </v-card-item>
    <v-divider></v-divider>
    <v-card-item>
        <v-row no-gutters>
            <v-col cols="4" class="text-blue">Sistemde Kayıtlı E-Posta Adresiniz:</v-col>
            <v-col cols="6">{{ userEMail }}</v-col>
        </v-row> 
    </v-card-item>
    <v-divider></v-divider>
    <v-card-item>
        <v-row justify="start">
            <v-col cols="3">
                <student-avatar></student-avatar>
            </v-col>
        </v-row>
    </v-card-item>
    <v-card-item>
        <v-row>
            <v-col cols=""></v-col>
            <v-col cols="auto" >
                <v-btn color="primary" variant="tonal" class="v-btn-no-caps" disabled="true">Bilgilerimi Düzenle</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="green" variant="tonal" class="v-btn-no-caps" @click="gotoUploadPhoto()"> Profil Resmimi Değiştir</v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn color="red" variant="tonal" class="v-btn-no-caps" @click="gotoChangePassword()"> Şifremi Değiştir</v-btn>
            </v-col>

        </v-row>
        
        

    </v-card-item>
</v-card>



</template>

<script setup>
    import { useUserStore } from '@/store/user';
    import {onMounted, computed } from 'vue';
    import StudentAvatar from './StudentAvatar.vue';
    import router from '@/router';
    const userStore = useUserStore();


    const userName = computed(() => userStore.getUserName);
    const userSurName = computed(() => userStore.getUserSurName);
    const userEMail = computed(() => userStore.getUserEMail);
    const studentNumber = computed(() => userStore.getStudentNumber);

    
    const gotoUploadPhoto= ()=>{
        router.push('/uploadphoto');
    }

    const gotoChangePassword= ()=>{
        router.push('/changepassword');
    }

    onMounted(async () => {
        await userStore.fetchStudentInformation();
        
    });

</script>


