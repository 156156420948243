import { defineStore } from "pinia"
import { useUserStore } from "./user"
import { generateBearerHeader } from "./helper"
import api from './api'

export const useInternshipCalendar = defineStore('studentCalendar',{
    state: ()=>({
        days: ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
        hours: ['09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00','18:00','19:00'],
        selectedHours: [],
        hasChanged: false,
        isLoading: false,
        confirmed: false,
        calendarStatus: 0
    }),
    getters: {
        getDays:(state)=>{
            return state.days;
        },
        getHours:(state)=>{
            return state.hours;
        },
        getSelectedHours:(state)=>{
            return state.selectedHours;
        },
        getHasChanged: (state)=>{
            return state.hasChanged;
        },
        getIsLoading: (state)=>{
            return state.isLoading;
        },
        getConfirmed: (state)=>{
            return state.confirmed;
        },
        getCalendarStatus: (state)=>{
            return state.calendarStatus;
        }
    },
    actions: {
        updateHasChanged(){
            this.hasChanged = true;

        },
        checkDateAndHour(day, hour){
            for(let i=0; i < this.selectedHours.length; i++){
                if(this.selectedHours[i].day === day && this.selectedHours[i].hour=== hour){
                    
                    return this.selectedHours[i].type;
                    
                }
            }
            return false

        },
        async insertArray(payLoad){
            let exist = this.checkDateAndHour(payLoad.day, payLoad.hour);
            if(exist){
                this.deleteArray({day: payLoad.day, hour: payLoad.hour});

            }
            this.selectedHours.push({day:payLoad.day, hour: payLoad.hour, type: payLoad.type})
            this.updateHasChanged();
        },
        async deleteArray(payLoad){
            for(let i=0; i < this.selectedHours.length; i++){
                if(this.selectedHours[i].day === payLoad.day && this.selectedHours[i].hour=== payLoad.hour){
                    this.selectedHours.splice(i,1);
                    break;
                }
            }

        },
        async fetchCalendarData(){
            const userStore = useUserStore();
            let token = userStore.getToken;
            let myHeader = generateBearerHeader(token);
            this.isLoading = true;
            const response = await api.get('/calendar', {headers: myHeader})
            this.selectedHours = [];
            if(response.status === 200){
                try {
                    this.selectedHours = JSON.parse(response.data.calendar)
                }
                catch{
                    this.selectedHours = [];
                }

                this.confirmed = response.data.confirmed;
                console.log(response.data.confirmed)
            }
            this.isLoading = false;
        },
        async fetchStudentCalendarData(id){
            const userStore = useUserStore();
            let token = userStore.getToken;
            let myHeader = generateBearerHeader(token);
            this.isLoading = true;
            const response = await api.get('/comitee_members/calendar/'+id, {headers: myHeader})
            this.selectedHours = [];
            if(response.status === 200){
                console.log(response)
                try {
                    this.selectedHours = JSON.parse(response.data.calendar_data)
                    
                    
                }
                catch{
                    this.selectedHours = [];
                }
            this.confirmed = response.data.confirmed;
            }
            this.isLoading = false;
        },
        async sendSelectedHours(){
            try {
                const userStore = useUserStore();
                let token = userStore.getToken;
                let myHeader = generateBearerHeader(token);
                this.isLoading = true;
                let postData = {
                    calendar_data: this.selectedHours 
                }
                const response = await api.post('/calendar',postData, {headers: myHeader})
                if(response.status === 200){
                    console.log("Takvim kayıt edildi.")
                }
    

            }
            catch(error){
                console.log(error);
            }
            finally{
                this.isLoading = false;
            }
            

        },
        async fetchCalendarStatus(){
            const userStore = useUserStore();
            let token = userStore.getToken;
            let myHeader = generateBearerHeader(token);
            try {
                const response = await api.get('/calendar/status', {headers: myHeader})
                if(response.status === 200){
                    this.calendarStatus = response.data.calendar_status;
                }
            }
            catch(error) {
                console.log(error);

            }
        },
        reset(){
            this.isLoading = false;
            this.confirmed = false;
            this.selectedHours = [];
            this.calendarStatus = 0;
        }


    },
    persist: {
        storage: sessionStorage,
        paths: ['isLoading','confirmed', 'selectedHours', 'calendarStatus']
    }
})