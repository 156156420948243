


<template>

           
    
        <v-sheet rounded="lg" border="sm" class="mt-2 mx-auto" >
            <h2 class="pa-2 ma-2">Staj Takvimi</h2>
            <v-divider></v-divider>
            <v-alert variant="tonal" class="ma-2" type="info" v-if="!confirmed">
                <v-alert-title>Staj Takvimi Hakkında Bilgilendirme</v-alert-title>
                <v-alert-description>
                    Staj yapacağınız gün ve saatleri haftalık programda işaretleyiniz. 
                    Programda her bir tıklama ile ilgili saaatteki aktivitenizi (Staj, Ders, Tez, Boş ) belirleyebilirsiniz.
                </v-alert-description>
            </v-alert>
            <v-alert
                v-if="confirmed"
                variant="tonal"
                type="success"
                class="ma-2"
            >
                Staj Takviminiz komisyon tarafından onaylanmıştır. Bu nedenle değişiklik yapamazsınız.
            </v-alert>

                <v-table class="ma-2" border>
                    <thead>
                        <tr>
                            <th></th>
                            <th v-for="day in calendarDays" :key="day" class="text-center">{{ day }}</th>
                        </tr>
                    </thead>
                    <tr v-for="hour in calendarHours" :key="hour" border >
                        <td class="table-with-border pa-2">{{ hour }}</td>
                        <td v-for="day in calendarDays" :key="day" class="text-center pa-2 table-with-border" bordered>
                            <span>
                                <v-btn v-if="!inserhipCalendarStore.checkDateAndHour(day,hour)" large variant="tonal" color="blue-lighten-1" @click="insertSelection(day, hour,'Staj')" class="v-btn-no-caps">Boş&nbsp;</v-btn>
                                <v-btn v-if="inserhipCalendarStore.checkDateAndHour(day, hour)==='Staj'" large variant="tonal" color="red-darken-2" @click="insertSelection(day, hour,'Ders')" class="v-btn-no-caps">Staj</v-btn>
                                <v-btn v-if="inserhipCalendarStore.checkDateAndHour(day, hour)==='Ders'" large variant="tonal" color="green-darken-3" @click="insertSelection(day, hour,'Tez')" class="v-btn-no-caps">Ders</v-btn>
                                <v-btn v-if="inserhipCalendarStore.checkDateAndHour(day, hour)==='Tez'" large variant="tonal" color="orange-accent-2" @click="deleteSelection(day, hour)" class="v-btn-no-caps">Tez&nbsp;</v-btn>
                            </span>
                        </td>
                    </tr>
                </v-table>
                <div v-if="!confirmed">
                    <v-btn 
                        
                        class="ma-2 v-btn-no-caps"
                        color="primary" 
                        :disabled="hasChanged===false"
                        @click="inserhipCalendarStore.sendSelectedHours()" >Kaydet</v-btn>
                </div>
               
        </v-sheet>
        <v-overlay 
            v-model = "isLoading"
            contained
            class="align-center justify-center"

        >
                    <v-progress-circular
                        :size="64"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>

        </v-overlay> 

</template>


<script setup>

import { computed, onMounted } from 'vue';
import { useInternshipCalendar } from '@/store/internshipcalendar';


const inserhipCalendarStore = useInternshipCalendar();


const calendarDays = inserhipCalendarStore.getDays;
const calendarHours = inserhipCalendarStore.getHours;
const isLoading = computed(() => inserhipCalendarStore.getIsLoading);
const hasChanged = computed(() => inserhipCalendarStore.getHasChanged);
const confirmed = computed(() => inserhipCalendarStore.getConfirmed);
const calendarStatus = computed(() => inserhipCalendarStore.getCalendarStatus);


const insertSelection = (day, hour, type) => {
    let data = {
        day: day,
        hour: hour,
        type: type
    
    }
    if (calendarStatus.value !==3){
        inserhipCalendarStore.insertArray(data);
    }
    
}

const deleteSelection = (day, hour) => {
    let data = {
        day: day,
        hour: hour
    }
    if (calendarStatus.value !==3){
        inserhipCalendarStore.deleteArray(data);
    }
    
}

onMounted(() => {
    inserhipCalendarStore.fetchCalendarData();
});

</script>

<style scoped>
    .table-with-border {
        border-bottom: 1px solid #CFD8DC;
        
    }





</style>