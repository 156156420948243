<template>
  <v-layout>
    <v-app-bar app :style="{ 'background-color': '#3e3d65', 'color': '#FFFFFF'}" class="white--text toolbar-title">
      
      <v-toolbar-title @click="gotoHomePage()"><v-icon class="ml-4" >mdi-school</v-icon> Eczacılık Staj Sistemi</v-toolbar-title>
      <v-spacer></v-spacer>

      <toolbar-button></toolbar-button>
      
    </v-app-bar>
    <v-main>
    <v-container fluid>
      <router-view/>
    </v-container>
    </v-main>
    <v-footer color="#3E3D65" app>
        <span class="white--text">&copy; 2024 Doç. Dr. Mehmet Zuhuri Arun</span>
    </v-footer>
  </v-layout>

  
</template>

<script setup>
  import router from './router';
  import ToolbarButton from '@/components/ToolBarButton.vue';

  const gotoHomePage = () => {
    router.push('/');
  }
</script>

<style lang="scss">
  body {
    background-color: #D4EDFE;
  }
  .bluey-blue-background {
    background-color: #3E3D65;
  }

  .toolbar-title {
    cursor: pointer;
    display: flex;
    
  }


  
/* styles içinde */
.v-btn-no-caps .v-btn__content {
  text-transform: none;
}


</style>
