<template>
    <v-card>
        <v-table class="ma-2" border>
                    <thead>
                        <tr>
                            <th></th>
                            <th v-for="day in calendarDays" :key="day" class="text-center">{{ day }}</th>
                        </tr>
                    </thead>
                    <tr v-for="hour in calendarHours" :key="hour" border >
                        <td class="table-with-border pa-2">{{ hour }}</td>
                        <td v-for="day in calendarDays" :key="day" class="text-center pa-2 table-with-border" border>
                            <span>
                                <v-chip label v-if="!calendarStore.checkDateAndHour(day,hour)" large variant="tonal" color="blue-lighten-1"  class="pa-2">Boş&nbsp;</v-chip>
                                <v-chip label v-if="calendarStore.checkDateAndHour(day, hour)==='Staj'" large variant="tonal" color="red-darken-2"  class="pa-2">Staj</v-chip>
                                <v-chip label v-if="calendarStore.checkDateAndHour(day, hour)==='Ders'" large variant="tonal" color="green-darken-3" class="pa-2">Ders</v-chip>
                                <v-chip label v-if="calendarStore.checkDateAndHour(day, hour)==='Tez'" large variant="tonal" color="orange-accent-2" class="pa-2">Tez&nbsp;</v-chip>
                            </span>

                        </td>
                    </tr>
                </v-table>




    </v-card>
</template>

<script setup>

import { onMounted, defineProps } from 'vue';

import { useInternshipCalendar } from '@/store/internshipcalendar';

const props = defineProps({
    uuid: String
});

const calendarStore = useInternshipCalendar();

const calendarDays = calendarStore.getDays;
const calendarHours = calendarStore.getHours;


onMounted(() => {
    calendarStore.fetchStudentCalendarData(props.uuid);
});

</script>