<template>
    <v-card>
        <v-card-item class="bg-primary">
            <v-card-title class="text-center">Staj İzleme Ziyareti - II</v-card-title>
        </v-card-item>
        <v-card-item>
            <v-row>
                <v-col cols="2">Tarih</v-col>
                <v-col cols="2">
                        <v-text-field variant="outlined" type="date" max-width="100" v-model="formData.tarih" v-on:change="formUpdated"></v-text-field>
                </v-col>
               <v-col></v-col>
            </v-row>
        </v-card-item>
        <v-card-item>
            <v-row>
                <v-col cols="2">Saat</v-col>
                <v-col cols="2">
                    <v-text-field variant="outlined" type="time" class="w-auto" v-model = "formData.saat" v-on:change="formUpdated"></v-text-field>
                </v-col>
                <v-col></v-col>
            </v-row>
        </v-card-item>
        <v-divider></v-divider>
        <v-card-item>
            <v-row>
                <v-col cols="2">Ziyaret sırasında öğrenci eczanededir.</v-col>
                <v-col cols="2">
                    <v-radio-group inline v-model="formData.ogrenci" v-on:change="formUpdated">
                        <v-radio label="Evet" value="1"></v-radio>
                        <v-radio label="Hayır" value="0"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">Hayırsa nedeni :</v-col>
                <v-col cols="2"><v-text-field variant="outlined" v-model="formData.neden" v-on:change="formUpdated"></v-text-field></v-col>
                <v-col></v-col>
            </v-row>
        </v-card-item>
        <v-divider></v-divider>
        <v-card-item>
            <v-row>
                <v-col cols="2">Stajyerin staj saatlerine uyumu ve devamı yeterlidir.</v-col>
                <v-col cols="2">
                    <v-radio-group inline v-model="formData.devam" v-on:change="formUpdated">
                        <v-radio label="Evet" value="1"></v-radio>
                        <v-radio label="Hayır" value="0"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">Hayırsa nedeni :</v-col>
                <v-col cols="2"><v-text-field variant="outlined" v-model="formData.devamneden" v-on:change="formUpdated"></v-text-field></v-col>
                <v-col></v-col>
            </v-row>
        </v-card-item>
        <v-divider></v-divider>
        <v-card-item>
            <v-row>
                <v-col cols="2">Staj sorunsuz tamamlanmıştır.</v-col>
                <v-col cols="2">
                    <v-radio-group inline v-model="formData.sorun" v-on:change="formUpdated">
                        <v-radio label="Evet" value="1"></v-radio>
                        <v-radio label="Hayır" value="0"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2">Hayırsa nedeni :</v-col>
                <v-col><v-text-field variant="outlined" v-model="formData.sorunneden" v-on:change="formUpdated"></v-text-field></v-col>
                <v-col></v-col>
            </v-row>
        </v-card-item>

    </v-card>
    <v-card class="mt-4">
        <v-card-item>
            <v-row>
                <v-col>
                    <v-btn class="bg-primary" :disabled="!isChanged" @click="submitForm()">Kaydet</v-btn>
                    
                    
                    
                </v-col>
                    
            </v-row>
            
            
        </v-card-item>
        <v-overlay 
            v-model = "isLoading"
            contained
            class="align-center justify-center"

        >
                    <v-progress-circular
                        :size="64"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>

        </v-overlay> 


    </v-card>


</template>


<script setup>


import { onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useFirstFormStore } from '@/store/firstform';


const route = useRoute();
const firstFormStore = useFirstFormStore();
const formData = computed(()=>firstFormStore.getFormData);
const isChanged = computed(()=>firstFormStore.getIsChanged);
const isLoading = computed(()=>firstFormStore.getIsLoading);

onMounted(() => {
    firstFormStore.fetchFormData(route.params.id, 'Form-2');
});

const formUpdated = ()=>{
    firstFormStore.updateHasChanged(true);
}

const submitForm = ()=>{
    firstFormStore.updateFormData(route.params.id, 'Form-2');
}

</script>